@import url(https://fonts.googleapis.com/css2?family=Fira+Code&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Nunito&display=swap);
@import url(https://fonts.googleapis.com/css?family=Roboto&display=swap);
@import url(https://fonts.googleapis.com/css?family=Source+Serif+Pro$=&display=swap);
.minor-warning {
    color:#F7C70A;
    font-weight: 500;
}

.major-warning {
    color:#FF0000;
}

.segment-stop-word::before {
    content: '\0025AA ';
    color:#F7C70A;
    margin-right: 2px;
    margin-bottom: 2px;
}

.cue-marker::before {
    content: '\0025B8';
    color:#1abbd4;
    margin-right: 2px;
    margin-bottom: 2px;
}

.cue-insert-point::before {
    content: '\0025B6';
    color:#1abbd4;
    margin-right: 2px;
    margin-bottom: 2px;
}

.script {
    font-family:Roboto;
}

.sentence {
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
    /* font-family: "Fira Code"; */
    margin-bottom: 1.3em;
    padding-bottom: .4em;
    line-height: 1.5em;
    font-weight: 300;
    /* font-size: .9em; */
}

.script-line {
    padding-left: 10px;
    border-left: 7px solid transparent;
    position: relative;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
    max-width: 1000px;
}

.cursor-is-under.script-line {
    border-left: 7px solid #F7C70A;
    /*border-radius: 3px;*/
}

.cursor-is-under.word {
    border-bottom: 2px solid #1abbd4 !important;
}

.paragraph-break {
    color: black;
    /* font-family: "Fira Code"; */
    margin-bottom: 1em;
}

.speaker-label {
    color: #78909c;
    /* font-family: "Fira Code"; */
    font-size: .9em;
    margin-top: .25em;
    margin-bottom: .4em;
}

.speaker-label .line-content {
    -webkit-text-decoration: underline #78909c;
            text-decoration: underline #78909c;
}

.passage-hint_ALT {
    /*color: #1a9bcb;*/
    font-weight: bold;
    /* font-family: "Fira Code"; */
    margin-top: 2.5em;
    margin-bottom: .8em;
    border-bottom: 2px solid #E6E1E8;
}

.passage-hint {
    /* margin-top: 2em; */
    /* margin-bottom: 1em; */
    /* border-left: 4px solid transparent; */
    font-family: "Source Serif Pro";
    font-size: 18px;
    margin-top: 2em;
    margin-bottom: 1em;
    line-height: 1.8em;
    border-bottom: 1px solid #999;
    margin-left: .5em;
    color: gray;
}


.chapter-title_ALT {
    color: black;
    /* font-family: "Fira Code"; */
    font-size: 1.5em;
    margin-top: 1em;
}

.chapter-title {
    line-height: 1.5em;
    font-size: 24px;
    text-align: center;
    color: #666;
    font-weight: 700;
    margin-top: 2em;
    margin-bottom: 1em;
}

.chapter-summary {
    /* font-family: "Fira Code"; */
    font-size: 1.1em;
    color: #777777;
    font-weight: bold;
    margin-top: 1em;
}

.cultural-note {
    /* font-family: "Fira Code"; */
    font-weight: bold;
    margin-top: .5em;
    margin-bottom: .5em;
}

.track-hover {
    text-align: left;
    color: #000000;
    background: #ffffff;
    font-family: Arial, Helvetica, sans-serif;
    position: absolute;
    z-index: 100;
    font-size: 17px;
    padding: 2px 4px;
    max-width: 100%;
    border-radius: 2px;
    border: 2px solid #deb100;
}

.status-bar-container {
    font-family: "Roboto";
    width: 1000px;
    overflow: hidden;
    white-space: nowrap;
    padding: 4px;
    border-bottom: 3px solid lightgrey;
}

.status-bar-element {
    overflow: hidden;
    display: inline-block;
    padding: 0px 40px;
    margin: 0px;
    vertical-align: bottom;
}

.cue-insert-point-target-text {
    font-family: Roboto;
    width: 1000px;
    display: inline-block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin-top: 5px;
    margin-left: 5px
}

.unsignedoff {
    background-color:#F7C70A20;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

